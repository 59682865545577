import { Injectable } from '@angular/core';

import { FlashcardTagsDialogBaseService } from '@stsm/flashcards/feature/services/flashcard-tags-dialog-base.service';
import { CreateEditMode } from '@stsm/shared/types/create-edit-mode';
import { Studyset } from '@stsm/studysets/models/studyset';
import { FlashcardTag } from '@stsm/tags/models/tag';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { FlashcardTagsDialogComponent } from './flashcard-tags-dialog.component';

@Injectable({ providedIn: 'root' })
export class FlashcardTagsDialogService extends FlashcardTagsDialogBaseService {
  constructor(private readonly _platformModalService: PlatformModalService) {
    super();
  }

  override showDialog({
    studyset,
    tags,
    isBulkEdit,
  }: {
    studyset: Studyset;
    tags: FlashcardTag[];
    mode?: CreateEditMode;
    isBulkEdit?: boolean;
  }): DialogRef<unknown, number[] | undefined> {
    return this._platformModalService.create({
      component: FlashcardTagsDialogComponent,
      data: {
        studyset,
        tags,
        isBulkEdit,
      },
      webOptions: {
        width: '300px',
      },
    });
  }
}
