import { FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from '@abacritt/angularx-social-login';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RecaptchaV3Module } from 'ng-recaptcha-2';
import { register } from 'swiper/element/bundle';

import { routerStateKey } from '@stsm/shared/router-store/router-state-key';

import { environment } from '../environments/environment';

import { getAppProviders } from './app.providers';
import { appRoutes } from './app.routes';
import { FroalaModule } from './components/froala';
import { initializeGoogleTagManager } from './initialize-google-tag-manager';
import { GOOGLE_TAG_MANAGER_ID } from './shared/constants/google-tag-manager-constants';
import { effects, reducers } from './store';

// register Swiper custom elements
register();

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideStore(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
        strictActionSerializability: true,
      },
    }),
    provideRouterStore({ stateKey: routerStateKey, navigationActionTiming: NavigationActionTiming.PostActivation }),
    provideEffects(effects),
    !environment.production ? provideStoreDevtools({ connectInZone: true }) : [],
    provideIonicAngular({ mode: 'ios' }),
    provideAppInitializer(() => {
      const initializerFn = initializeGoogleTagManager(inject(GoogleTagManagerService));

      return initializerFn();
    }),
    importProvidersFrom([
      AngularSvgIconModule.forRoot(),
      GoogleTagManagerModule.forRoot({
        id: GOOGLE_TAG_MANAGER_ID,
      }),
      FroalaModule.forRoot(),
      SocialLoginModule.initialize({
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // This clientId is configured in a different Google Cloud Project than the client ids used for mobile (https://console.cloud.google.com/)
            provider: new GoogleLoginProvider('778285925256-5vldtpla3bsf89elcqh9ntiuqp5ab2nf.apps.googleusercontent.com', {
              scopes: ['email'],
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('152261295515152'),
          },
        ],
      }),
      RecaptchaV3Module,
    ]),
    ...getAppProviders(),
  ],
};
