import { inject } from '@angular/core';
import { isNil } from 'lodash-es';
import { firstValueFrom, switchMap, tap } from 'rxjs';

import { FlashcardsAnalyticsService } from '@stsm/flashcards/services/flashcards-analytics.service';
import { FlashcardsBaseService } from '@stsm/flashcards/services/flashcards-base-service';
import { FLASHCARDS_SERVICE } from '@stsm/flashcards/services/tokens/flashcards-service.token';
import { Flashcard } from '@stsm/flashcards/types/flashcard';
import { FlashcardLocation } from '@stsm/flashcards/types/flashcard-location';
import { CreateEditMode } from '@stsm/shared/types/create-edit-mode';
import { arraysContainSameValues } from '@stsm/shared/util/array-util';
import { switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { FlashcardTag, TagContentFilter } from '@stsm/tags/models/tag';
import { TagsStoreFacade } from '@stsm/tags/services/store/tags-store-facade.service';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

import { FlashcardTagsService } from './flashcard-tags.service';

export abstract class FlashcardTagsDialogBaseService {
  private readonly _tagsStoreFacade: TagsStoreFacade = inject(TagsStoreFacade);
  private readonly _flashcardTagsService: FlashcardTagsService = inject(FlashcardTagsService);
  private readonly _flashcardsService: FlashcardsBaseService = inject(FLASHCARDS_SERVICE);
  private readonly _flashcardsAnalyticsService: FlashcardsAnalyticsService = inject(FlashcardsAnalyticsService);

  async showModalAndUpdateFlashcard({
    studyset,
    flashcard,
    mode,
    location,
    isBulkEdit,
  }: {
    studyset: Studyset;
    flashcard: Flashcard;
    mode: CreateEditMode;
    location: FlashcardLocation;
    isBulkEdit?: boolean;
  }): Promise<void> {
    const tagData = await firstValueFrom(this._tagsStoreFacade.tags(studyset.id, TagContentFilter.FLASHCARDS));

    const inputFlashcardTags = this._flashcardTagsService.getFlashcardTags(flashcard, tagData.allParentTags);

    const dialogRef = this.showDialog({ studyset, tags: inputFlashcardTags, isBulkEdit, mode });

    return firstValueFrom(
      dialogRef.afterClosed().pipe(
        switchMap((outputTagIds: number[] | undefined) => {
          if (
            isNil(outputTagIds) ||
            arraysContainSameValues(
              outputTagIds,
              inputFlashcardTags.map((tag: FlashcardTag) => tag.id),
            )
          ) {
            return VOID;
          }

          return this._flashcardsService.updateFlashcardTags(studyset.id, flashcard, outputTagIds).pipe(
            tap(() => {
              this._flashcardsAnalyticsService.trackTagSubmitEvent(outputTagIds, inputFlashcardTags, tagData.allParentTags, mode, location);
            }),
          );
        }),
        switchToVoid(),
      ),
    );
  }

  abstract showDialog(params: {
    studyset: Studyset;
    tags: FlashcardTag[];
    mode?: CreateEditMode;
    isBulkEdit?: boolean;
  }): DialogRef<unknown, number[] | undefined>;
}
