import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { Ad } from '@stsm/global/models/ad';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { ConfettiCanvasComponent } from '@stsm/ui-components/confetti-canvas';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

@Component({
  selector: 'app-lead-gen-ad-modal-reward',
  imports: [TranslatePipe, ButtonComponent, ConfettiCanvasComponent, IconButtonComponent],
  templateUrl: './lead-gen-ad-modal-reward.component.html',
  styleUrls: ['./lead-gen-ad-modal-reward.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadGenAdModalRewardComponent {
  @Input({ required: true }) ad!: Ad;

  @Input({ required: true }) rewardDurationInDays!: number;

  @ViewChild(ConfettiCanvasComponent) confettiCanvas?: ConfettiCanvasComponent;

  constructor(private readonly _platformModalService: PlatformModalService) {
    setTimeout(() => {
      void this.confettiCanvas?.triggerConfetti();
    }, 500);
  }

  protected dismiss(): void {
    this._platformModalService.dismiss();
  }
}
