import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, ElementRef, Signal, signal, WritableSignal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { KeyboardService } from '@stsm/shared/services/keyboard.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { DotBadgeComponent } from '@stsm/ui-components/dot-badge/dot-badge.component';

import {
  AiAssistantFloatingChatStore,
  AiAssistantFloatingChatViewModel,
  AssistantAnimationState,
  isChatAnimationState,
} from '../../services/ai-assistant-floating-chat.store';
import { AiAssistantPremiumService, AiAssistantPremiumViewModel } from '../../services/ai-assistant-premium.service';
import { AiAssistantComponent } from '../ai-assistant/ai-assistant.component';
import { aiAssistantAnimations } from '../ai-assistant/ai-assistant-animations';
import { ChatTopRowComponent } from '../chat-top-row/chat-top-row.component';

@UntilDestroy()
@Component({
  selector: 'app-ai-assistant-button',
  imports: [AiAssistantComponent, ChatTopRowComponent, DotBadgeComponent],
  templateUrl: './ai-assistant-button.component.html',
  styleUrl: './ai-assistant-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    aiAssistantAnimations.assistantPositioningContainerAnimation,
    aiAssistantAnimations.assistantLayoutContainerAnimation,
    aiAssistantAnimations.assistantContentAnimation,
    aiAssistantAnimations.assistantFadeInOutAnimation,
  ],
  host: {
    '[@aiAssistant]': '{ value: vm.animationState(), params: { chatHeight: chatHeight() } }',
    '(@aiAssistant.done)': `onAnimationEnd($event)`,
    '[class.is-full-page-route]': 'isFullPageLayout()',
    '[class.chat]': `vm.isChatOpen()`,
    'data-cy': 'ai-assistant-button',
  },
})
export class AiAssistantButtonComponent {
  protected readonly vm: AiAssistantFloatingChatViewModel = this._aiAssistantFloatingChatStore.vm;
  protected readonly showChatContent: WritableSignal<boolean> = signal(false);
  protected readonly isFullPageLayout: Signal<boolean> = this._aiAssistantFloatingChatStore.isFullPageLayout;
  protected readonly showNotificationBadge: Signal<boolean> = computed(() => this.vm.showNotificationBadge() && !this.vm.isChatOpen());

  protected readonly hasReducedHeight: Signal<boolean> = computed(
    () => this.vm.hasReducedHeight() && !this._keyboardService.isKeyboardVisible(),
  );

  protected readonly chatHeight: Signal<string> = computed(() => {
    // assistant exceeds viewport height in mobile browser
    // reserve space for browser controls
    if (this._layoutStore.isWebAppInPhoneLayout()) {
      return '80vh';
    }

    return this.hasReducedHeight() ? '60vh' : '100vh';
  });

  protected readonly animationState: Signal<AssistantAnimationState> = computed(() => {
    return this.vm.animationState();
  });

  protected readonly isGeneratingResponse: Signal<boolean> = computed(() => this.vm.isChatOpen() && this.vm.isGeneratingResponse());

  protected readonly premiumVm: AiAssistantPremiumViewModel = this._aiAssistantPremiumService.vm;
  protected readonly showBottomSheetToggle: Signal<boolean> = computed(
    () => !this._keyboardService.isKeyboardVisible() && this._layoutStore.isMobileLayout(),
  );

  constructor(
    private readonly _aiAssistantFloatingChatStore: AiAssistantFloatingChatStore,
    private readonly _aiAssistantPremiumService: AiAssistantPremiumService,
    private readonly _keyboardService: KeyboardService,
    private readonly _layoutStore: LayoutStore,
    private readonly _elementRef: ElementRef,
  ) {}

  onAnimationEnd(event: AnimationEvent): void {
    const previousAnimationState = event.fromState as AssistantAnimationState;
    const currentAnimationState = event.toState as AssistantAnimationState;
    this.showChatContent.set(!isChatAnimationState(previousAnimationState) && isChatAnimationState(currentAnimationState));
  }

  onCloseClick(): void {
    if (this.vm.animationState() === 'pill') {
      this._aiAssistantFloatingChatStore.resetPillMessage();
    } else {
      this._aiAssistantFloatingChatStore.closeChat();
    }
  }

  openAssistant(): void {
    if (!this.vm.isChatOpen()) {
      this._aiAssistantFloatingChatStore.openChat('user');
    }
  }

  toggleChatHeight(event: TouchEvent | MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this._aiAssistantFloatingChatStore.setHasReducedHeightFromUser(!this.vm.hasReducedHeight());
  }

  reset(): void {
    void this._aiAssistantFloatingChatStore.reset(this._elementRef);
  }
}
