import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { PREMIUM_MODAL_SERVICE } from '@stsm/premium/tokes/premium-modal-service.token';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';

import { AdLocation } from '../../models/ad-location';
import { SidebarAdsStore } from '../../services/sidebar-ads-store.service';
import { AdGroup } from '../../util/ad-sidebar-util';
import { AdvertisementComponent } from '../advertisement/advertisement.component';

@Component({
  selector: 'app-enigma-sidebar',
  templateUrl: './ads-sidebar.component.html',
  styleUrl: './ads-sidebar.component.scss',
  imports: [NgIf, AdvertisementComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'enigma-sidebar',
  },
})
export class AdsSidebarComponent {
  protected readonly currentAdGroup: Signal<AdGroup | undefined> = toSignal(this._sidebarAdsStore.currentAdGroup$);
  protected readonly isFullPageRouteActive: Signal<boolean> = toSignal(this._layoutStore.isFullPageRouteActive$, { initialValue: true });

  protected readonly AdLocation: typeof AdLocation = AdLocation;

  constructor(
    private readonly _layoutStore: LayoutStore,
    private readonly _sidebarAdsStore: SidebarAdsStore,
    @Inject(PREMIUM_MODAL_SERVICE) private readonly _premiumModalService: PremiumModalService,
  ) {}

  protected onGetPremiumClick(): void {
    this._premiumModalService.openPremiumModal({ source: 'right_sidebar' });
  }
}
