import { ChangeDetectionStrategy, Component, computed, input, InputSignal, Signal } from '@angular/core';

import { Ad } from '@stsm/global/models/ad';
import { LeadQuestion, LeadQuestionType } from '@stsm/global/models/lead-question';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

import { LeadGenAdModalLeadQuestionComponent } from '../lead-gen-ad-modal-lead-question/lead-gen-ad-modal-lead-question.component';

@Component({
  selector: 'app-lead-gen-ad-entry-question-dialog',
  imports: [DialogTemplateComponent, LeadGenAdModalLeadQuestionComponent],
  templateUrl: './lead-gen-ad-entry-question-dialog.component.html',
  styleUrl: './lead-gen-ad-entry-question-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'lead-gen-ad-entry-question-dialog',
  },
})
export class LeadGenAdEntryQuestionDialogComponent {
  readonly ad: InputSignal<Ad> = input.required<Ad>();

  protected readonly question: Signal<LeadQuestion> = computed(() => {
    const ad = this.ad();

    return {
      answers: ad.entryAnswerOptions,
      id: 0,
      question: ad.entryQuestion,
      type: LeadQuestionType.SINGLE_SELECT,
      conditions: [],
    };
  });

  constructor(private readonly _dialogRef: DialogRef<LeadGenAdEntryQuestionDialogComponent, string | undefined>) {}

  protected onUserAnswer(answers: string[]): void {
    this._dialogRef.dismiss(answers[0]);
  }
}
