import { Injectable } from '@angular/core';
import { UrlSegment, UrlTree } from '@angular/router';
import { of, switchMap } from 'rxjs';

import { TimeTrackFeature, WEB_TIME_TRACK_FEATURES } from '@stsm/analytics';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { FeatureTimeTrackingBaseService } from '@stsm/global/composite/services/feature-time-tracking-base.service';
import { Tab } from '@stsm/shared/enums/tab';
import { JsonObject } from '@stsm/shared/types/json-object';
import { getEventPropsForStudyset, Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureTimeTrackingService extends FeatureTimeTrackingBaseService {
  private _currentStudyset: Studyset | undefined;

  constructor(
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _appActiveService: AppActiveService,
  ) {
    super();

    this._appActiveService.isAppActive$.subscribe((isAppActive: boolean) => {
      isAppActive ? this.startTracking() : this.stopTracking();
    });

    this.routerStoreFacade.studysetIdFromRoute$
      .pipe(switchMap((studysetId?: number) => (studysetId ? this._studysetsStoreFacade.studysetById(studysetId) : of(undefined))))
      .subscribe((studyset: Studyset | undefined) => {
        this._currentStudyset = studyset;
      });
  }

  override getStudysetEventProps(): JsonObject {
    return this._currentStudyset ? getEventPropsForStudyset(this._currentStudyset) : {};
  }

  override resolveFeatureFromUrl(url: string): TimeTrackFeature {
    const urlTree: UrlTree = this.router.parseUrl(url);
    const urlParts: string[] = urlTree?.root?.children?.['primary']?.segments?.map((seg: UrlSegment) => seg.path) ?? [];

    // detect feature by url parts

    if (urlParts.includes(Tab.DEALS)) {
      return WEB_TIME_TRACK_FEATURES.deals;
    } else if (urlParts.includes('spaced-repetition')) {
      return WEB_TIME_TRACK_FEATURES.spacedRepetition;
    } else if (urlParts.includes('quiz')) {
      /** QUIZ */
      return WEB_TIME_TRACK_FEATURES.quiz;
    } else if (urlParts.includes('documents')) {
      /** DOCUMENTS */
      return WEB_TIME_TRACK_FEATURES.document;
    } else if (urlParts.includes('flashcards') && urlParts.includes('learn')) {
      /** FLASHCARDS */
      return WEB_TIME_TRACK_FEATURES.flashcards;
    } else if (urlParts.includes('flashcards') && urlParts.includes('create')) {
      return WEB_TIME_TRACK_FEATURES.standaloneEditor;
    } else if (urlParts.includes('exercises')) {
      /** EXERCISES */
      return WEB_TIME_TRACK_FEATURES.exercises;
    } else if (urlParts.includes('summary')) {
      if (urlParts.includes('edit')) {
        /** SUMMARY */
        return WEB_TIME_TRACK_FEATURES.summary;
      } else {
        /** SUMMARY_PREVIEW */
        return WEB_TIME_TRACK_FEATURES.studysetSummaryPreview;
      }
    } else if (urlParts.includes(Tab.HOME)) {
      /** HOME */
      return WEB_TIME_TRACK_FEATURES.home;
    } else if (urlParts.includes(Tab.LIBRARY)) {
      /** STUDYSETS */
      return WEB_TIME_TRACK_FEATURES.library;
    } else if (urlParts.includes('studyset')) {
      /** STUDYSETS */
      return WEB_TIME_TRACK_FEATURES.studyset;
    } else if (urlParts.includes(Tab.PROFILE)) {
      /** PROFILE */
      return WEB_TIME_TRACK_FEATURES.profile;
    } else if (urlParts.includes('login')) {
      /** LOGIN */
      return WEB_TIME_TRACK_FEATURES.login;
    } else if (urlParts.includes('register') || urlParts.includes('create-user-password')) {
      /** REGISTER */
      return WEB_TIME_TRACK_FEATURES.register;
    } else if (urlParts.includes('reset-password')) {
      /** FORGOT_PASSWORD */
      return WEB_TIME_TRACK_FEATURES.forgotPassword;
    } else if (urlParts.includes('code')) {
      /** CODE */
      return WEB_TIME_TRACK_FEATURES.code;
    } else if (urlParts.includes('onboarding')) {
      /** ONBOARDING */
      return WEB_TIME_TRACK_FEATURES.onboarding;
    } else if (urlParts.includes('textbooks')) {
      return WEB_TIME_TRACK_FEATURES.textbooks;
    } else if (urlParts.includes('smart-explain')) {
      return WEB_TIME_TRACK_FEATURES.explainAi;
    } else if (urlParts.includes('flashcard-search-details')) {
      return WEB_TIME_TRACK_FEATURES.flashcardSearchDetails;
    } else if (urlParts.includes(Tab.SEARCH)) {
      return WEB_TIME_TRACK_FEATURES.search;
    }

    return WEB_TIME_TRACK_FEATURES.unknown;
  }
}
