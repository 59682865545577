import { NgForOf, NgIf } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import JSZip from 'jszip';
import { floor, merge as _merge } from 'lodash-es';
import { debounceTime, Observable, Subject, tap } from 'rxjs';

import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { PlatformBaseService } from '@stsm/shared/services/platform-base.service';
import { PLATFORM_SERVICE } from '@stsm/shared/tokens/platform-service.token';

import { Language } from '../models/language';
import { SUPPORTED_LANGUAGES, SupportedLanguage } from '../models/supported-language';
import { TranslationMap } from '../models/translation-map';
import { TranslationService } from '../services/translation.service';

const BE_LOKALISE_API: string = ' https://lokalise.studysmarter-test.de/';

@UntilDestroy()
@Component({
  selector: 'app-translation-toolbar',
  templateUrl: './translation-toolbar.component.html',
  styleUrls: ['./translation-toolbar.component.scss'],
  imports: [ReactiveFormsModule, NgForOf, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationToolbarComponent implements OnDestroy {
  @HostBinding('class.config-view') showDownloadConfig: boolean = false;

  multiplyFactor: number = 1;
  isLoading: boolean = false;
  errorDuringDownload: boolean = false;

  currentLanguage: SupportedLanguage = 'en';
  isPupil: boolean = false;

  branches: string[] = [];

  readonly showAdvancedFeatures: boolean = false; // deactivate for now

  get lokaliseMapChange$(): Observable<void> {
    return this._lokaliseMapChange$.asObservable();
  }

  get showKeysChange$(): Observable<void> {
    return this._showKeysChange$.asObservable();
  }

  get multiplyFactorChange$(): Observable<void> {
    return this._multiplyFactorChange$.asObservable();
  }

  get destroy$(): Observable<void> {
    return this._destroy$.asObservable();
  }

  get showTranslationKeys(): boolean {
    return this._showTranslationKeys;
  }

  readonly supportedLanguages: typeof SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

  readonly showKeysControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });
  readonly isPupilControl: FormControl<boolean> = new FormControl<boolean>(this.isPupil, { nonNullable: true });
  readonly languageControl: FormControl<SupportedLanguage> = new FormControl<SupportedLanguage>(
    this._translationService.currentLanguage(),
    {
      nonNullable: true,
    },
  );

  readonly branchControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  readonly languageDownloadControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });
  readonly multiplyFactorControl: FormControl<number> = new FormControl<number>(this.multiplyFactor, { nonNullable: true });

  private _downloadAllLanguages: boolean = false;
  private _currentBranch?: string;

  private _showTranslationKeys: boolean = false;

  private readonly _lokaliseMap: { [language: string]: TranslationMap } = {};

  private readonly _lokaliseMapChange$: Subject<void> = new Subject<void>();
  private readonly _showKeysChange$: Subject<void> = new Subject<void>();
  private readonly _multiplyFactorChange$: Subject<void> = new Subject<void>();
  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _translationService: TranslationService,
    private readonly _httpClient: HttpClient,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _browserStorageService: BrowserStorageService,
    @Inject(PLATFORM_SERVICE) private readonly _platformService: PlatformBaseService,
  ) {
    this._translationService.registerDevToolbar(this);

    this.isPupil = this._browserStorageService.getItemLocalStorage<boolean>(GlobalLocalStorageKey.IS_PUPIL) ?? false;

    this._translationService.language$.pipe(untilDestroyed(this)).subscribe((language: Language) => {
      this.languageControl.setValue(language.value, { emitEvent: false });
      this.currentLanguage = language.value;
      this._changeDetectorRef.markForCheck();
    });

    this.showKeysControl.valueChanges.pipe(untilDestroyed(this)).subscribe((showKeys: boolean) => {
      this._showTranslationKeys = showKeys;
      this._showKeysChange$.next();
    });

    this.languageControl.valueChanges.pipe(untilDestroyed(this)).subscribe((language: SupportedLanguage) => {
      this._translationService.useLanguage(language);
      this._changeDetectorRef.markForCheck();
    });

    this.isPupilControl.valueChanges.pipe(untilDestroyed(this)).subscribe((isPupil: boolean) => {
      this.isPupil = isPupil;
      this._translationService.useLanguage(this._translationService.currentLanguage());
    });

    this.branchControl.valueChanges.pipe(untilDestroyed(this)).subscribe((branch: string) => {
      this._currentBranch = branch;
    });

    this.languageDownloadControl.valueChanges.pipe(untilDestroyed(this)).subscribe((downloadAllLanguages: boolean) => {
      this._downloadAllLanguages = downloadAllLanguages;
    });

    this.multiplyFactorControl.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe((multiplyFactor: number) => {
      this.multiplyFactor = Math.max(1, Math.min(99, multiplyFactor));
      this.multiplyFactorControl.setValue(this.multiplyFactor, { emitEvent: false });
      this._multiplyFactorChange$.next();
    });

    if (this.showAdvancedFeatures) {
      this._httpClient
        .get(BE_LOKALISE_API, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          params: {
            action: 'branches',
          },
        })
        .pipe(
          tap((branches: object) => {
            if (Array.isArray(branches)) {
              this.branches = branches;
              this.branchControl.setValue(branches.includes('master') ? 'master' : branches[0]);
              this._changeDetectorRef.markForCheck();
            }
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getUpdatedFiles(): void {
    this.isLoading = true;
    this.errorDuringDownload = false;
    const languagesToFetch: string[] = this._downloadAllLanguages
      ? [...SUPPORTED_LANGUAGES, 'de-school']
      : [this.currentLanguage, ...(this.currentLanguage === 'de' ? ['de-school'] : [])];

    const params = {
      action: 'download',
      platform: this._platformService.isMobile() ? 'Mobile' : 'WebApp',
      languages: languagesToFetch.join(','),
      ...(this._currentBranch ? { branch: this._currentBranch } : {}),
    };

    this._httpClient
      .get(BE_LOKALISE_API, {
        headers: new HttpHeaders({
          Accept: 'application/zip',
        }),
        responseType: 'arraybuffer',
        params,
      })
      .pipe(
        tap((res: ArrayBuffer) => {
          const zip = new JSZip();
          void zip
            .loadAsync(res)
            .then((data: JSZip) => {
              return Promise.all(
                Object.keys(data.files).map((fileName: string) => {
                  const file = data.files[fileName];
                  const language = fileName.split('.')[0];

                  if (file?.dir) {
                    return Promise.resolve();
                  }

                  return file?.async('string').then((content: string) => {
                    this._lokaliseMap[language as string] = JSON.parse(content) as TranslationMap;
                  });
                }),
              );
            })
            .then(() => {
              this._lokaliseMapChange$.next();
              this.isLoading = false;
              this._changeDetectorRef.markForCheck();
            });
        }),
        untilDestroyed(this),
      )
      .subscribe({
        error: (error: Error) => {
          console.error(error);
          this.errorDuringDownload = true;
          this.isLoading = false;
          this._changeDetectorRef.markForCheck();
        },
      });
  }

  getCurrentLokaliseTranslationMap(file: string, isPupil?: boolean): TranslationMap | undefined {
    const baseTranslation: TranslationMap | undefined = this._lokaliseMap[this._getLokaliseMapKey(file, false)];

    if (isPupil && baseTranslation) {
      const pupilExtension = this._lokaliseMap[this._getLokaliseMapKey(file, true)];

      return _merge({}, baseTranslation, pupilExtension ?? {});
    }

    return baseTranslation ?? undefined;
  }

  getMultipliedValue(value: string): string {
    let output = value;

    const cycles = floor(this.multiplyFactor);
    const overflow = this.multiplyFactor - cycles;

    for (let i = 0; i < cycles - 1; i++) {
      output += ' ' + value;
    }

    if (overflow !== 0) {
      output += ' ' + value.substring(0, floor(value.length * overflow));
    }

    return output;
  }

  private _getLokaliseMapKey(language: string, isPupil: boolean): string {
    return language + (isPupil ? '-school' : '');
  }
}
