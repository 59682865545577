import { AdFlashcardId, Flashcard, FlashcardFactory } from '@stsm/flashcards/types/flashcard';
import { Ad } from '@stsm/global/models/ad';

export function getFlashcardForAd(advertisement: Ad, flashcardId: AdFlashcardId): Flashcard {
  return FlashcardFactory.create({
    id: flashcardId,
    questionFlashcardEntry: { text: '', isCorrect: true },
    answerFlashcardEntries: [{ text: '', isCorrect: true }],
    advertisement,
  });
}
