import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  Inject,
  inject,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, distinctUntilChanged, map, Subject } from 'rxjs';

import { AdPopupService } from '@stsm/advertisement/components/ad-popup/ad-popup.service';
import { AdsSidebarComponent } from '@stsm/advertisement/components/ads-sidebar/ads-sidebar.component';
import { SidebarAdsStore } from '@stsm/advertisement/services/sidebar-ads-store.service';
import { AiAssistantButtonComponent } from '@stsm/ai-assistant/components/ai-assistant-button/ai-assistant-button.component';
import { AI_ASSISTANT_PAGE_OFFSET } from '@stsm/ai-assistant/constants/animation-timing';
import { AiAssistantFloatingChatStore } from '@stsm/ai-assistant/services/ai-assistant-floating-chat.store';
import { FULL_PAGE_ROUTE_CLASS_NAME } from '@stsm/shared/constants/full-page-route-class-name';
import { SizeObserverDirective } from '@stsm/shared/directives';
import { Tab } from '@stsm/shared/enums/tab';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { KeyboardService } from '@stsm/shared/services/keyboard.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { NetworkService } from '@stsm/shared/services/network.service';
import { PaypalConfigService } from '@stsm/shared/services/paypal-config.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { Size } from '@stsm/shared/types/size';
import { ColorId } from '@stsm/shared/util/color-util';
import { mutationObservable } from '@stsm/shared/util/rxjs.util';
import { ThemedDirective } from '@stsm/ui-components/disco-color';

import { NavigationComponent } from './components/navigation/navigation.component';
import { OfflineDisclaimerComponent } from './components/offline-disclaimer/offline-disclaimer.component';
import { profileRouteUrls } from './routes/profile-route-url';
import { scaffoldContentTransitionAnimation } from './scaffold-content-transition-animation';
import { ScaffoldStore } from './scaffold-store.service';

@UntilDestroy()
@Component({
  selector: 'app-scaffold',
  imports: [
    NavigationComponent,
    NgIf,
    OfflineDisclaimerComponent,
    SizeObserverDirective,
    AdsSidebarComponent,
    IonRouterOutlet,
    AiAssistantButtonComponent,
  ],
  templateUrl: './scaffold.component.html',
  styleUrls: ['./scaffold.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'scaffold',
    '[class.show-sidebar]': 'isSidebarShown()',
    '[class.hide-nav]': 'hideNav()',
    '[class.offline-disclaimer-shown]': 'isOfflineDisclaimerShown()',
    '[style.--ai-assistant-offset]': 'showAiAssistant() ? AI_ASSISTANT_PAGE_OFFSET : ""',
  },
  hostDirectives: [
    {
      directive: ThemedDirective,
      inputs: ['colorId'],
    },
  ],
  providers: [
    // provide SidebarAdsStore here instead of in root to make sure a new instance is created when logging out and back in
    SidebarAdsStore,
    AdPopupService,
  ],
})
export class ScaffoldComponent implements OnInit {
  @ViewChild('outlet', { read: ElementRef, static: true })
  outletRef!: ElementRef;

  isSidebarShown: Signal<boolean | undefined> = this._layoutStore.isSidebarShown;

  protected readonly isKeyboardVisible: WritableSignal<boolean> = signal(false);
  protected readonly isSearchPage: Signal<boolean> = computed(
    () => this._scaffoldStore.currentTab() === Tab.SEARCH && this._routerStoreFacade.url().includes(Tab.SEARCH),
  );

  protected readonly isProfileTab: Signal<boolean> = computed(
    () =>
      this._scaffoldStore.currentTab() === Tab.PROFILE &&
      !profileRouteUrls.some((profileRoute: string) => this._routerStoreFacade.url().includes(profileRoute)),
  );

  protected readonly showAiAssistant: Signal<boolean> = computed(() =>
    this._aiAssistantFloatingChatStore.vm.isAssistantEnabledForCurrentPage(),
  );

  protected readonly hideNav: Signal<boolean> = computed(() => {
    const isOnSpecificPage = this.isSearchPage() || this.isProfileTab();

    return (this.isKeyboardVisible() && isOnSpecificPage) || this._aiAssistantFloatingChatStore.vm.isChatOpen();
  });

  protected readonly isOfflineDisclaimerShown: WritableSignal<boolean> = signal(false);

  protected readonly scaffoldContentTransitionAnimation: typeof scaffoldContentTransitionAnimation = scaffoldContentTransitionAnimation;

  protected readonly AI_ASSISTANT_PAGE_OFFSET: string = AI_ASSISTANT_PAGE_OFFSET;

  private readonly _sidebarHeight$: Subject<number> = new Subject<number>();

  constructor(
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    private readonly _layoutStore: LayoutStore,
    private readonly _themedDirective: ThemedDirective,
    private readonly _paypalConfigService: PaypalConfigService,
    private readonly _keyboardService: KeyboardService,
    private readonly _scaffoldStore: ScaffoldStore,
    private readonly _routerStoreFacade: RouterStoreFacade,
    private readonly _sidebarAdsStore: SidebarAdsStore,
    private readonly _networkService: NetworkService,
    private readonly _aiAssistantFloatingChatStore: AiAssistantFloatingChatStore,
  ) {
    if (!this.isMobileApp) {
      inject(AdPopupService).init();
    }

    this._keyboardService.isKeyboardVisible$.pipe(untilDestroyed(this)).subscribe((isKeyboardVisible: boolean) => {
      this.isKeyboardVisible.set(isKeyboardVisible);
    });

    this._networkService.showOfflineDisclaimer$.pipe(untilDestroyed(this)).subscribe((showOfflineDisclaimer: boolean) => {
      this.isOfflineDisclaimerShown.set(showOfflineDisclaimer);
    });

    // if (this._platformService.isDevice) {
    //   // https://ionicframework.com/docs/developing/hardware-back-button#exiting-the-app
    //   inject(Platform).backButton.subscribeWithPriority(-1, () => {
    //     if (!this._routerOutlet.canGoBack()) {
    //       void App.exitApp();
    //     }
    //   });
    // }

    this._sidebarAdsStore.setContainerHeight(this._sidebarHeight$.pipe(distinctUntilChanged()));

    this._layoutStore.setIsSidebarShown(
      combineLatest([this._layoutStore.canShowSidebarBasedOnBreakpoint$, this._sidebarAdsStore.areAdsAvailable$]).pipe(
        map(([isCorrectBreakpoint, areAdsAvailable]: [boolean, boolean]) => isCorrectBreakpoint && areAdsAvailable),
      ),
    );

    this._layoutStore.currentTheme$.pipe(untilDestroyed(this)).subscribe((theme: ColorId | undefined) => {
      this._themedDirective.colorId = theme;
      // eslint-disable-next-line @angular-eslint/no-lifecycle-call
      this._themedDirective.ngOnChanges();
    });

    this._paypalConfigService.loadPaypalScript();
  }

  ngOnInit(): void {
    this._layoutStore.setIsFullPageRouteActive(
      (this.outletRef.nativeElement as Element).children.item(0)?.classList.contains(FULL_PAGE_ROUTE_CLASS_NAME) ?? false,
    );

    mutationObservable(this.outletRef.nativeElement, {
      childList: true,
    })
      .pipe(untilDestroyed(this))
      .subscribe(() => this._updateIsFullPageRouteActive());
  }

  protected onSidebarSizeChange(size: Size): void {
    this._sidebarHeight$.next(size.height);
  }

  private _updateIsFullPageRouteActive(): void {
    const children = (this.outletRef.nativeElement as Element).children;

    for (const element of Array.from(children)) {
      if (element.classList.contains(FULL_PAGE_ROUTE_CLASS_NAME)) {
        this._layoutStore.setIsFullPageRouteActive(true);

        return;
      }
    }

    this._layoutStore.setIsFullPageRouteActive(false);
  }
}
