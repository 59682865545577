import { JsonObject } from '@stsm/shared/types/json-object';

export interface ProjectedRevenueData {
  projectedRevenue: number;
}

export interface PurchaseResult extends ProjectedRevenueData {
  didPurchase: boolean;
}

export function getProjectedRevenueFromResponse(res: JsonObject): ProjectedRevenueData {
  return { projectedRevenue: (res['projected_revenue'] ?? 0) as number };
}
