import { ChangeDetectionStrategy, Component, computed, Inject, Input, Signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';
import { catchError, finalize, map, Observable, switchMap, tap } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { getDateString } from '@stsm/date/functions/get-date-string';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { LogoIdentifier, LogoSource } from '@stsm/shared/models/logo-source';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { latestFrom, VOID } from '@stsm/shared/util/rxjs.util';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { LoadingService } from '@stsm/ui-components/loading-dialog/loading.service';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { PremiumBaseService } from '../../services/premium-base.service';
import { PREMIUM_SERVICE } from '../../tokes/premium-base-service.token';
import { PremiumFeaturesComponent } from '../premium-features/premium-features.component';

@UntilDestroy()
@Component({
  selector: 'app-cancel-premium',
  templateUrl: './cancel-premium.component.html',
  styleUrls: ['./cancel-premium.component.scss'],
  imports: [PremiumFeaturesComponent, ButtonComponent, TranslatePipe, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ['data-cy']: 'cancel-premium-dialog' },
})
export class CancelPremiumComponent {
  @Input() productId!: string;

  protected readonly premiumLogoSource: Signal<LogoSource> = computed(() => {
    const logoIdentifier: LogoIdentifier = this._themingStore.darkThemeActive() ? 'premium-white' : 'premium';

    return this._targetMarketProvider.resolveLogo(logoIdentifier)();
  });

  protected readonly premiumEndDate$: Observable<string> = this._userStoreFacade.premiumInfo$.pipe(
    map((premiumInfo: PremiumInfo) => (!isNil(premiumInfo.endDate) ? getDateString(premiumInfo.endDate) : '')),
  );

  constructor(
    private readonly _themingStore: ThemingStore,
    private readonly _loadingService: LoadingService,
    private readonly _translationService: TranslationService,
    private readonly _loggerService: LoggerService,
    private readonly _toastService: ToastService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _sentryService: SentryService,
    private readonly _dialogRef: DialogRef,
    @Inject(PREMIUM_SERVICE) private readonly _premiumService: PremiumBaseService,
    private readonly _targetMarketProvider: TargetMarketProvider,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {}

  protected cancelPremium(): void {
    this._analyticsService.trackEvent({
      action: 'premium_manage_modal_interact',
      properties: {
        action: 'confirm_cancel_premium',
        productId: this.productId,
      },
    });

    this._loadingService.showLoading(this._translationService.get('PREMIUM.PLANS.CANCELING'));

    this._premiumService
      .cancelSubscription()
      .pipe(
        switchMap(() => this._premiumService.refreshPremiumInfo()),
        latestFrom(this.premiumEndDate$),
        tap((endDateString: string) => {
          this._toastService.successToast({
            key: 'PREMIUM.CANCELLED_MESSAGE',
            params: { endDateString },
          });
          this._dialogRef.dismiss(true);
        }),
        finalize(() => this._loadingService.hideLoading()),
        catchError((error: Error) => {
          this._loggerService.warn(error);
          this._toastService.errorToast('PREMIUM.CANCELLED_MESSAGE_ERROR');
          this._sentryService.reportToSentry('[PREMIUM][CANCELLATION]', {
            productId: this.productId,
            error,
          });

          return VOID;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected keepPremium(): void {
    this._dialogRef.dismiss(false);
  }
}
