import { NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';

@Component({
  selector: 'app-studygroup-email',
  templateUrl: './studygroup-email.component.html',
  styleUrls: ['./studygroup-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, NgIf],
  host: {
    'data-cy': 'studygroup-member-email',
  },
})
export class StudygroupEmailComponent {
  @Input({ required: true }) email: string = '';
  @Input({ transform: booleanAttribute }) isPending: boolean = false;
}
