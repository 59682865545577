import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { PayOption } from './pay-option/pay-option';
import { PayOptionComponent } from './pay-option/pay-option.component';

@Component({
  selector: 'app-pay-options-list',
  templateUrl: './pay-options-list.component.html',
  styleUrls: ['./pay-options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, PayOptionComponent, TranslatePipe, NgIf, IconButtonComponent],
})
export class PayOptionsListComponent {
  @Input() payOptions: PayOption[] = [];
  @Input() selectedPayOption: PayOption | undefined;
  @Input() activeSubscriptionId: string | undefined;

  @Input() mode: 'dialog' | 'component' = 'dialog';

  @Output() readonly optionSelected: EventEmitter<PayOption> = new EventEmitter<PayOption>();

  constructor(private readonly _platformModalService: PlatformModalService) {}

  onPayOptionSelected(payOption: PayOption): void {
    if (this.mode === 'dialog') {
      this._platformModalService.dismiss(payOption);
    } else {
      this.optionSelected.emit(payOption);
    }
  }

  isPayOptionSelected(payOption: PayOption): boolean {
    return this.selectedPayOption?.productID === payOption.productID;
  }

  isPayOptionActive(payOption: PayOption): boolean {
    return this.activeSubscriptionId === payOption.productID;
  }

  close(): void {
    this._platformModalService.dismiss();
  }

  trackByPayOption(_: number, payOption: PayOption): string {
    return payOption.productID;
  }
}
