import { Routes } from '@angular/router';

import { ENABLE_AD_POPUP_ROUTE_KEY } from '@stsm/advertisement/components/ad-popup/ad-popup.service';
import { dealsTabCanActivateGuard } from '@stsm/affiliate/feature/guards/deals-tab-can-activate.guard';
import { disableAiAssistantGuard } from '@stsm/ai-assistant/guards/disable-ai-assistant.guard';
import { AI_ASSISTANT_ENABLED, AI_ASSISTANT_FULL_PAGE_LAYOUT } from '@stsm/ai-assistant/services/ai-assistant-floating-chat.store';
import { disableFlashcardBulkEditOnLeave } from '@stsm/flashcards/feature/guards/disable-flashcard-bulk-edit-on-leave.guard';
import { jobsPageGuard } from '@stsm/jobs/feature/guards/jobs-page.guard';
import { onboardingRunningGuard } from '@stsm/onboarding/control/guards/onboarding-running.guard';
import { profileRoutes } from '@stsm/profile/profile.routes';
import { NavigationRoute } from '@stsm/scaffold/models/navigation-route';
import { canDeactivateFnForNavAnimation } from '@stsm/scaffold/router-util/can-deactivate-fn-for-nav-animation';
import { ScaffoldComponent } from '@stsm/scaffold/scaffold.component';
import { Tab } from '@stsm/shared/enums/tab';
import { studysetRedirectGuard } from '@stsm/studysets/util/studyset-redirect.guard';

import { environment } from '../environments/environment';

import { isRouteAllowedForAnonymousUserGuard } from './+auth/guards/is-route-allowed-for-anonymous-user.guard';
import { loggedInGuard } from './+auth/guards/logged-in.guard';
import { MobileDisclaimerComponent } from './+auth/mobile-disclaimer/mobile-disclaimer.component';
import { mobileDisclaimerGuard } from './+auth/mobile-disclaimer/mobile-disclaimer.guard';
import { exerciseFormCanDeactivateGuard } from './+exercises/exercise-edit/exercise-form.can-deactivate.guard';
import { documentViewerCanDeactivateGuard } from './+lectures/document-viewer-page/document-viewer-can-deactivate.guard';
import { contentCreatorGuard } from './content-creator.guard';
import { standaloneEditorCanDeactivateGuard } from './flashcards/standalone-editor/standalone-editor-can-deactivate.guard';
import { AuthZoneComponent } from './zones/auth-zone/auth-zone.component';

export const NAVIGATION_ROUTES: NavigationRoute[] = [
  {
    path: Tab.HOME,
    loadChildren: () => import('./feed/feed.routes').then((m) => m.feedRoutes),
    title: 'GLOBAL.NAVIGATION_ITEMS.HOME',
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.HOME',
      appIcon: 'app-icon-home',
      appIconActive: 'app-icon-home-fill',
    },
  },
  {
    path: Tab.LIBRARY,
    loadComponent: () => import('@stsm/library/feature').then((m) => m.LibraryComponent),
    title: 'GLOBAL.NAVIGATION_ITEMS.LECTURES',
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.LECTURES',
      appIcon: 'app-icon-library',
      appIconActive: 'app-icon-library-fill',
      [AI_ASSISTANT_ENABLED]: true,
    },
  },
  {
    path: Tab.SEARCH,
    loadChildren: () => import('@stsm/search/search-page/search.routes').then((m) => m.searchRoutes),
    title: 'GLOBAL.NAVIGATION_ITEMS.EXPLORE',
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.EXPLORE',
      appIcon: 'app-icon-compass',
      appIconActive: 'app-icon-compass-fill',
    },
  },
  {
    path: Tab.JOBS,
    loadComponent: () => import('@stsm/jobs/page/jobs-page.component').then((m) => m.JobsPageComponent),
    title: 'GLOBAL.NAVIGATION_ITEMS.JOBS',
    canActivate: [jobsPageGuard],
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.JOBS',
      appIcon: 'app-icon-briefcase',
      appIconActive: 'app-icon-briefcase-fill',
    },
  },
  {
    path: Tab.DEALS,
    loadComponent: () => import('@stsm/affiliate/page/affiliate-page.component').then((m) => m.AffiliatePageComponent),
    title: 'GLOBAL.NAVIGATION_ITEMS.DEALS',
    canActivate: [dealsTabCanActivateGuard],
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.DEALS',
      appIcon: 'app-icon-sale',
      appIconActive: 'app-icon-sale-fill',
    },
  },
  {
    path: Tab.PROFILE,
    title: 'GLOBAL.NAVIGATION_ITEMS.PROFILE',
    children: profileRoutes,
    data: {
      textTranslationKey: 'GLOBAL.NAVIGATION_ITEMS.PROFILE',
      appIcon: 'app-icon-user',
      appIconActive: 'app-icon-user-fill',
    },
  },
];

const deprecatedFallbackRoutes: Routes = [
  {
    path: 'planner',
    redirectTo: '/home',
  },
  {
    path: 'account',
    redirectTo: '/profile',
  },
];

export const appRoutes: Routes = [
  {
    path: '',
    component: AuthZoneComponent,
    // Do not use the authZoneGuard here as the auth routes also contain routes that are available when being logged in
    // (e.g. signup-website for the website-to-webapp connection)
    children: [
      {
        path: '',
        loadChildren: () => import('./+auth/auth.routes').then((m) => m.authRoutes),
      },
    ],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.routes').then((m) => m.onboardingRoutes),
    canActivate: [loggedInGuard, onboardingRunningGuard],
  },
  {
    path: '',
    component: ScaffoldComponent,
    canActivate: [loggedInGuard],
    canActivateChild: [mobileDisclaimerGuard, loggedInGuard, isRouteAllowedForAnonymousUserGuard],
    children: [
      ...NAVIGATION_ROUTES,
      {
        path: 'mobile-disclaimer',
        component: MobileDisclaimerComponent,
        canActivate: [mobileDisclaimerGuard],
      },
      {
        // old route (studysets)
        path: 'studysets/:studysetId',
        canActivate: [studysetRedirectGuard],
        children: [],
      },
      {
        path: 'studyset/:studysetId',
        title: 'GLOBAL.NAVIGATION_ITEMS.LECTURES',
        children: [
          {
            path: '',
            loadChildren: () => import('@stsm/studyset-detail/feature/studyset-page.routes').then((m) => m.studysetPageRoutes),
          },
          {
            path: 'flashcards',
            children: [
              {
                path: '',
                loadComponent: () => import('@stsm/flashcard-grid/flashcard-grid.component').then((m) => m.FlashcardGridComponent),
                canDeactivate: [canDeactivateFnForNavAnimation, disableFlashcardBulkEditOnLeave],
                data: {
                  [AI_ASSISTANT_ENABLED]: true,
                },
              },
              {
                path: 'quiz',
                loadChildren: () => import('./+quiz/quiz.routes').then((m) => m.quizRoutes),
                canDeactivate: [canDeactivateFnForNavAnimation],
              },
              {
                path: 'spaced-repetition',
                loadChildren: () =>
                  import('./flashcards/spaced-repetition/spaced-repetition-page.routes').then((m) => m.spacedRepetitionPageRoutes),
                canDeactivate: [canDeactivateFnForNavAnimation],
              },
              {
                path: 'learn',
                children: [
                  {
                    path: ':flashcardId',
                    loadComponent: () =>
                      import('./flashcards/flashcards-trainer/flashcards-trainer.component').then((m) => m.FlashcardsTrainerComponent),
                    canDeactivate: [canDeactivateFnForNavAnimation],
                    canActivate: [disableAiAssistantGuard],
                    data: {
                      [ENABLE_AD_POPUP_ROUTE_KEY]: true,
                    },
                  },
                  {
                    path: '**',
                    redirectTo: '-1',
                  },
                ],
              },
              {
                path: 'create',
                loadComponent: () =>
                  import('./flashcards/standalone-editor/standalone-editor.component').then((m) => m.StandaloneEditorComponent),
                canActivate: [mobileDisclaimerGuard],
                canDeactivate: [standaloneEditorCanDeactivateGuard, canDeactivateFnForNavAnimation],
              },
            ],
          },
          {
            path: 'exercises',
            children: [
              {
                path: '',
                loadComponent: () => import('@stsm/exercises/exercise-grid/exercise-grid.component').then((m) => m.ExerciseGridComponent),
                canDeactivate: [canDeactivateFnForNavAnimation],
                data: {
                  [AI_ASSISTANT_ENABLED]: true,
                },
              },
              {
                path: ':exerciseId',
                loadComponent: () => import('./+exercises/exercise-page/exercise-page.component').then((m) => m.ExercisePageComponent),
                canDeactivate: [canDeactivateFnForNavAnimation],
                data: {
                  [AI_ASSISTANT_FULL_PAGE_LAYOUT]: true,
                  [AI_ASSISTANT_ENABLED]: true,
                },
              },
              {
                path: ':exerciseId/edit',
                canActivate: [contentCreatorGuard],
                canDeactivate: [exerciseFormCanDeactivateGuard],
                loadComponent: () => import('./+exercises/exercise-edit/exercise-edit.component').then((m) => m.ExerciseEditComponent),
              },
            ],
          },
          {
            path: 'summary/:summaryId',
            loadChildren: () => import('./+summary-page/summary.routes').then((m) => m.summaryRoutes),
            canDeactivate: [canDeactivateFnForNavAnimation],
            data: {
              [AI_ASSISTANT_FULL_PAGE_LAYOUT]: true,
              [AI_ASSISTANT_ENABLED]: true,
            },
          },
          {
            path: 'documents/:slidesetId',
            loadComponent: () =>
              import('./+lectures/document-viewer-page/document-viewer-page.component').then((m) => m.DocumentViewerPageComponent),
            canDeactivate: [documentViewerCanDeactivateGuard, canDeactivateFnForNavAnimation],
            data: {
              [ENABLE_AD_POPUP_ROUTE_KEY]: true,
              [AI_ASSISTANT_FULL_PAGE_LAYOUT]: true,
              [AI_ASSISTANT_ENABLED]: true,
            },
          },
          {
            path: '**',
            redirectTo: '',
          },
        ],
      },
      {
        path: 'textbook/:textbookId',
        loadComponent: () => import('@stsm/textbook-detail/feature/textbook-page.component').then((m) => m.TextbookPageComponent),
        canDeactivate: [canDeactivateFnForNavAnimation],
        data: {
          [AI_ASSISTANT_ENABLED]: true,
        },
      },
      {
        path: 'textbook/:textbookId/chapters/:chapterId/exercises/:exerciseId',
        loadComponent: () => import('./+exercises/exercise-page/exercise-page.component').then((m) => m.ExercisePageComponent),
        canDeactivate: [canDeactivateFnForNavAnimation],
        data: {
          [AI_ASSISTANT_FULL_PAGE_LAYOUT]: true,
          [AI_ASSISTANT_ENABLED]: true,
        },
      },
      {
        path: 'brand/:brandId',
        loadComponent: () =>
          import('@stsm/affiliate/page/brand-details-page/brand-details-page.component').then((m) => m.BrandDetailsPageComponent),
        canDeactivate: [canDeactivateFnForNavAnimation],
      },
      {
        path: '',
        redirectTo: environment.CONTENT_CREATORS ? '/library' : '/home',
        pathMatch: 'full',
      },
    ],
  },
  ...deprecatedFallbackRoutes,
  {
    path: 'contribute',
    loadComponent: () => import('./+lectures/join-studygroup/join-studygroup.component').then((m) => m.JoinStudygroupComponent),
  },
  {
    path: 'system-maintenance',
    loadChildren: () => import('./system-maintenance/system-maintenance.routes').then((m) => m.systemMaintenanceRoutes),
  },
  {
    path: '',
    redirectTo: environment.CONTENT_CREATORS ? '/library' : '/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadComponent: () => import('./web-deeplink-matcher.component').then((m) => m.WebDeeplinkMatcherComponent),
  },
];
