import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { Tab } from '@stsm/shared/enums/tab';
import { User } from '@stsm/user/models/user';
import { shouldShowDealsForUser } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

export const dealsTabCanActivateGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);

  return inject(UserStoreFacade).user$.pipe(map((user: User) => shouldShowDealsForUser(user) || router.parseUrl(Tab.HOME)));
};
