<a class="logo-menu-item" (click)="onLogoClick()">
  <img alt="" [src]="logo$ | async" />
</a>

<div *ngrxLet="{ currentTab: currentTab$, indicators: indicators$ } as vm" class="items-stack items">
  @for (item of visibleNavigationRoutes(); track item.path) {
    <a [attr.data-cy]="'tabs-button-' + [item.path]" (click)="onNavItemClicked(item.path)">
      <ui-toolbar-item
        [icon]="vm.currentTab === item.path ? item.data.appIconActive : item.data.appIcon"
        [label]="item.data.textTranslationKey | translate"
        [isActive]="vm.currentTab === item.path && !isFullPageRouteActive()"
        [showUnseenIndicator]="vm.indicators[item.path]"
        [flat]="isMobileLayout()"
      />
    </a>
  }
</div>

<div class="items-stack actions">
  <ui-toolbar-item
    *ngIf="!isMobileApp"
    icon="app-icon-help"
    data-cy="tabs-button-help"
    [label]="'GLOBAL.NAVIGATION_ACTIONS.HELP' | translate"
    (click)="openFeedbackModal()"
  />
  <ui-toolbar-item
    icon="app-icon-referral"
    data-cy="tabs-button-invite-friends"
    [label]="'GLOBAL.REFERRAL' | translate"
    (click)="onInviteFriendsClick()"
  />
</div>
