import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, input, InputSignal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { isNil } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';

import { PremiumModalCallSource } from '@stsm/analytics';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { PlanDuration, PremiumPlan } from '@stsm/premium/models/premium-plan';
import { IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { PaypalComponent } from './paypal/paypal.component';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  imports: [NgIf, PaypalComponent, TranslatePipe, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'payment-dialog',
  },
})
export class PaymentDialogComponent {
  @Input({ required: true }) source!: PremiumModalCallSource;

  premiumPlan: InputSignal<PremiumPlan> = input.required<PremiumPlan>();

  isTransactionInProcess: boolean = false;

  protected readonly priceInfo: Signal<string> = computed(() => {
    const premiumPlan = this.premiumPlan();

    if (premiumPlan.duration === PlanDuration.MONTHLY) {
      if (premiumPlan.hasTrial) {
        return this._translationService.get('FREE_TRIAL.SELECTED_PLAN_INFO_MONTHLY', { price: premiumPlan.monthlyPrice });
      }

      return this._translationService.get('FREE_TRIAL.SELECTED_PLAN_INFO_MONTHLY_NO_TRIAL', { price: premiumPlan.monthlyPrice });
    }

    const introductoryOffer = premiumPlan.introductoryOffer;

    if (!isNil(introductoryOffer)) {
      return this._translationService.get('FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY_TRIAL', {
        trialInfo: this._translationService.get('FREE_TRIAL.PAID_TRIAL_MONTH_INFO', {
          count: introductoryOffer.billingCycleCount,
          price: introductoryOffer.displayPrice,
        }),
        price: premiumPlan.monthlyPrice,
      });
    }

    return this._translationService.get(
      premiumPlan.hasTrial ? 'FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY' : 'FREE_TRIAL.SELECTED_PLAN_INFO_YEARLY_NO_TRIAL',
      { price: premiumPlan.monthlyPrice },
    );
  });

  protected readonly usedFreeTrial: Signal<boolean> = toSignal(
    this._userStoreFacade.premiumInfo$.pipe(
      map((premiumInfo: PremiumInfo) => premiumInfo.usedFreeTrial),
      distinctUntilChanged(),
    ),
    { initialValue: false },
  );

  constructor(
    private readonly _dialogRef: DialogRef<PaymentDialogComponent>,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _translationService: TranslationService,
  ) {}

  onTransactionCompleted(): void {
    this._dialogRef.dismiss(true);
  }

  onTransactionInProcess(isInProcess: boolean): void {
    this.isTransactionInProcess = isInProcess;
    this._dialogRef.canClose = !isInProcess;
  }

  onCloseDialog(): void {
    if (!this.isTransactionInProcess) {
      this._dialogRef.dismiss(false);
    }
  }
}
