import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, Inject, input, InputSignal, OnInit, Signal } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { Slideset } from '@stsm/documents/models/slideset';
import { SlidesetByIdPipe } from '@stsm/documents/store/pipes/slideset-by-id.pipe';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { Id } from '@stsm/shared/types/id';
import { Summary } from '@stsm/summaries/models/summary';
import { SummaryByIdPipe } from '@stsm/summaries/store/pipes/summary-by-id.pipe';
import { AccordionComponent, AccordionItemComponent } from '@stsm/ui-components/accordion';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';

import { FileItemComponent } from '../file-item/file-item.component';

export interface MaterialVisibilityDialogData {
  slidesets: Slideset[];
  summaries: Summary[];
}

export interface MaterialVisibilityResult {
  slidesetIds: Id[];
  summaryIds: Id[];
}

@Component({
  selector: 'app-material-visibility-dialog',
  imports: [
    DialogTemplateComponent,
    TranslatePipe,
    ButtonComponent,
    AccordionComponent,
    AccordionItemComponent,
    FileItemComponent,
    SlidesetByIdPipe,
    AsyncPipe,
    SummaryByIdPipe,
  ],
  templateUrl: './material-visibility-dialog.component.html',
  styleUrl: './material-visibility-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'material-visibility-dialog',
  },
})
export class MaterialVisibilityDialogComponent implements OnInit {
  slidesets: InputSignal<Slideset[]> = input<Slideset[]>([]);
  summaries: InputSignal<Summary[]> = input<Summary[]>([]);

  slidesetIds: Signal<Id[]> = computed(() => this.slidesets().map((slideset: Slideset) => slideset.id));
  summaryIds: Signal<Id[]> = computed(() => this.summaries().map((summary: Summary) => summary.id));

  form: FormGroup<{ slidesets: FormArray<FormControl<boolean>>; summaries: FormArray<FormControl<boolean>> }> = new FormGroup({
    slidesets: new FormArray<FormControl<boolean>>([]),
    summaries: new FormArray<FormControl<boolean>>([]),
  });

  get selectedSlidesets(): number {
    return this.form.controls.slidesets.value.filter(Boolean).length;
  }

  get selectedSummaries(): number {
    return this.form.controls.summaries.value.filter(Boolean).length;
  }

  constructor(
    private readonly _dialogRef: DialogRef,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {
    effect((): void => {
      this.slidesets().forEach((): void => {
        this.form.controls.slidesets.push(new FormControl<boolean>(true, { nonNullable: true }));
      });
    });

    effect((): void => {
      this.summaries().forEach((): void => {
        this.form.controls.summaries.push(new FormControl<boolean>(true, { nonNullable: true }));
      });
    });
  }

  ngOnInit(): void {
    this._analyticsService.trackEvent({ action: 'studyset_material_visibility_open' });
  }

  toggleSelection(event: MouseEvent, formArray: FormArray<FormControl<boolean>>, items: { permissions: { canEdit: boolean } }[]): void {
    event.stopPropagation();
    const hasSomeSelected: boolean = formArray.controls.some((control: FormControl<boolean>) => control.value);

    if (hasSomeSelected) {
      formArray.controls.forEach((control: FormControl<boolean>) => control.setValue(false));
    } else {
      formArray.controls.forEach((control: FormControl<boolean>, index: number): void => {
        control.setValue(items[index]?.permissions.canEdit ?? false);
      });
    }
  }

  closeDialog(): void {
    this._dialogRef.dismiss(false);
  }

  confirmSelection(): void {
    const { slidesets, summaries } = this.form.controls;
    this._analyticsService.trackEvent({
      action: 'studyset_material_visibility_submit',
      properties: {
        allFilesSelected: slidesets.value.every(Boolean),
        allNotesSelected: summaries.value.every(Boolean),
      },
    });
    const result: MaterialVisibilityResult = {
      slidesetIds: this._getSelectedIds(this.slidesetIds(), slidesets.value),
      summaryIds: this._getSelectedIds(this.summaryIds(), summaries.value),
    };
    this._dialogRef.dismiss(result);
  }

  private _getSelectedIds(ids: Id[], selectionArray: boolean[]): Id[] {
    return ids.filter((_: number, index: number) => selectionArray[index]);
  }
}
