import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LocaleDateStringPipe } from '@stsm/date/pipes/locale-date-string.pipe';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { SystemMaintenanceInfo } from '@stsm/system-maintenance/models/system-maintenance';
import { SystemMaintenanceStore } from '@stsm/system-maintenance/services/system-maintenance-store.service';
import { IllustrationDirective } from '@stsm/ui-components/disco-color/illustration.directive';

@UntilDestroy()
@Component({
  selector: 'app-system-maintenance-banner',
  templateUrl: './system-maintenance-banner.component.html',
  styleUrls: ['./system-maintenance-banner.component.scss'],
  imports: [IllustrationDirective, LocaleDateStringPipe, NgIf, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('moveInOut', [
      transition(':enter', [style({ transform: 'translateY(-100%)' }), animate('150ms', style({ transform: 'translateY(0)' }))]),
      transition(':leave', [style({ transform: 'translateY(0)' }), animate('150ms', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class SystemMaintenanceBannerComponent implements OnInit {
  showBanner: boolean = false;
  plannedStart: string | undefined;
  plannedEnd: string | undefined;

  constructor(
    private readonly _systemMaintenanceStore: SystemMaintenanceStore,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._systemMaintenanceStore.showSystemMaintenanceBanner$.pipe(untilDestroyed(this)).subscribe((showBanner: boolean) => {
      this.showBanner = showBanner;
      this._changeDetectorRef.markForCheck();
    });

    this._systemMaintenanceStore.systemMaintenanceInfo$.pipe(untilDestroyed(this)).subscribe((info: SystemMaintenanceInfo) => {
      this.plannedStart = info.plannedMaintenanceStart;
      this.plannedEnd = info.plannedMaintenanceEnd;
      this._changeDetectorRef.markForCheck();
    });
  }

  close(): void {
    this.showBanner = false;
  }
}
