import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, input, InputSignal, OnInit, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService, ShareDialogOpenSource } from '@stsm/analytics';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { BadgeComponent } from '@stsm/ui-components/badge';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { ThemedDirective } from '@stsm/ui-components/disco-color';
import { InfoCardComponent } from '@stsm/ui-components/info-card';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

@UntilDestroy()
@Component({
  selector: 'app-sharing-dialog',
  templateUrl: './sharing-dialog.component.html',
  styleUrls: ['./sharing-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslatePipe,
    DialogTemplateComponent,
    InfoCardComponent,
    ButtonComponent,
    BadgeComponent,
    ReactiveFormsModule,
    ThemedDirective,
  ],
})
export class SharingDialogComponent implements OnInit {
  readonly source: InputSignal<ShareDialogOpenSource> = input.required();

  sharingLink: string = '';
  signupsGenerated: number = 0;
  isMobileLayout: Signal<boolean> = this._layoutStore.isMobileLayout;

  constructor(
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translationService: TranslationService,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _toastService: ToastService,
    private readonly _layoutStore: LayoutStore,
  ) {}

  ngOnInit(): void {
    this._userStoreFacade.user$
      .pipe(
        tap((user: User) => {
          this.signupsGenerated = user.signupsGenerated;
          this.sharingLink = this._generateShareLink(user);
          this._changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  protected onClose(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'close',
        source: this.source(),
      },
    });
  }

  protected copyShareLink(): void {
    void navigator.clipboard.writeText(this.sharingLink);
    this._toastService.successToast('SHARE.LINK_COPIED');
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'copy_link',
        source: this.source(),
      },
    });
  }

  protected shareViaMail(): void {
    this._analyticsService.trackEvent({
      action: 'share_app_modal_interact',
      properties: {
        action: 'share_via_email',
        source: this.source(),
      },
    });

    const shareTitle = this._translationService.get('SHARE.SHARING_DIALOG.APP_TITLE');
    const shareText = this._translationService.get('SHARE.SHARING_DIALOG.APP_TEXT');

    window.location.href = `mailto:...?subject=${shareTitle}&body=${shareText}%0A%0A${encodeURIComponent(this.sharingLink)}`;
  }

  private _generateShareLink(user: User): string {
    const baseUrl = `https://${this._environment.WEBAPP_URL}`;

    return `${baseUrl}/register?web_source=activeuserreferral&utm_medium=webapp&utm_term=mailreferral&ref=${user.refId}`;
  }
}
