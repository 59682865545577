import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { NetworkService } from '@stsm/shared/services/network.service';
import { ThemedDirective } from '@stsm/ui-components/disco-color';

@UntilDestroy()
@Component({
  selector: 'app-offline-disclaimer',
  templateUrl: './offline-disclaimer.component.html',
  styleUrls: ['./offline-disclaimer.component.scss'],
  imports: [TranslatePipe, ThemedDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineDisclaimerComponent implements OnInit {
  @HostBinding('style.display')
  display: 'none' | '' = 'none';

  set visible(visible: boolean) {
    this.display = visible ? '' : 'none';
  }

  constructor(
    private readonly _networkService: NetworkService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._networkService.showOfflineDisclaimer$.pipe(untilDestroyed(this)).subscribe((showOfflineDisclaimer: boolean) => {
      this.visible = showOfflineDisclaimer;
      this._changeDetectorRef.markForCheck();
    });
  }
}
