import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { filter, finalize, interval, Observable, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';

import { PremiumModalCallSource } from '@stsm/analytics';
import { DevtoolsActionLabel, DevtoolsService } from '@stsm/devtools/services/devtools.service';
import { MONEY_BACK_PAGE_DE, MONEY_BACK_PAGE_EN } from '@stsm/premium/constants/money-back-links';
import { HasPremiumDialogPurchaseEvent } from '@stsm/premium/models/has-premium-dialog-purchase-event';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { PremiumModalService } from '@stsm/premium/services/premium-modal.service';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { LoadingService } from '@stsm/ui-components/loading-dialog/loading.service';
import { PremiumInfo } from '@stsm/user/models/premium-info';

import { PaymentDialogService } from '../../components/payment-dialog/payment-dialog.service';

import { PremiumService } from './premium.service';

const PAYPAL_TEST_PREMIUM_PLAN_PRODUCT_ID = 'P-99G50945JT902301SMRRD4MQ';

@Injectable({
  providedIn: 'root',
})
export class PremiumModalWebService extends PremiumModalService {
  constructor(
    private readonly _paymentDialogService: PaymentDialogService,
    private readonly _loadingService: LoadingService,
    private readonly _premiumService: PremiumService,
    private readonly _devtoolsService: DevtoolsService,
  ) {
    super();

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.PURCHASE_PAYPAL_TEST_PLAN,
      action: () => {
        this.premiumPlansService.getPremiumPlans().subscribe((premiumPlans: PremiumPlan[]) => {
          const paypalTestPlan = premiumPlans.find((plan: PremiumPlan) => plan.productID === PAYPAL_TEST_PREMIUM_PLAN_PRODUCT_ID);

          if (isNil(paypalTestPlan)) {
            this.loggerService.warn('Paypal Testplan not found!');

            return;
          }

          this._paymentDialogService.show(paypalTestPlan, 'devtool');
        });
      },
    });
  }

  override openMoneyBackGuaranteeLink(): void {
    const link: string = this.translationService.currentLanguage() === 'de' ? MONEY_BACK_PAGE_DE : MONEY_BACK_PAGE_EN;

    window.open(link, '_blank');
  }

  protected override registerPurchasePremiumPlanListener(
    dialogRef: DialogRef<HasPremiumDialogPurchaseEvent>,
    source: PremiumModalCallSource,
  ): void {
    dialogRef.componentInstance?.instance.purchasePlan
      .pipe(
        withLatestFrom(this.userStoreFacade.premiumInfo$),
        switchMap(([premiumPlan, premiumInfo]: [PremiumPlan, PremiumInfo]) => {
          if (!isNil(premiumInfo.activeSubscriptionID)) {
            this.analyticsService.trackEvent({
              action: 'premium_plans_switch_plan',
            });

            return this._switchToPlan(premiumPlan).pipe(tap(() => dialogRef.dismiss()));
          } else {
            return this._paymentDialogService
              .show(premiumPlan, source)
              .afterClosed()
              .pipe(tap((hasPurchased: boolean | undefined) => hasPurchased && dialogRef.dismiss()));
          }
        }),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe();
  }

  private _switchToPlan(premiumPlan: PremiumPlan): Observable<void> {
    this._loadingService.showLoading(this.translationService.get('PREMIUM.PLANS.SWITCHING'));

    return this._premiumService.crossgradeSubscription(premiumPlan.productID).pipe(
      finalize(() => this._loadingService.hideLoading()),
      switchMap((confirmationLink: string) => {
        const confirmationWindow = window.open(confirmationLink, 'popup', 'width=700,height=700,');

        return interval(1000).pipe(
          filter(() => !isNil(confirmationWindow) && confirmationWindow.closed),
          take(1),
          switchMap(() => this._premiumService.refreshPremiumInfo()),
        );
      }),
      switchToVoid(),
    );
  }
}
