<form [formGroup]="form" (submit)="onSubmit()">
  <app-tag-input
    formControlName="tags"
    [studysetId]="studyset.id"
    [allParentTags]="(allParentTags$ | async) ?? []"
    [isBulkEdit]="isBulkEdit"
    [contentFilter]="TagContentFilter.FLASHCARDS"
  />

  <div class="buttons">
    <button
      *ngIf="isTagManagementAvailable()"
      uiButton
      secondary
      data-cy="tag-dialog-manage"
      [attr.id]="GlobalElementId.TAG_MANAGEMENT_BUTTON"
      (click)="openTagManagementDialog($event)"
    >
      <i class="app-icon-tags" slot="icon"></i>
      {{ "FLASHCARD.TAG_MANAGEMENT.MANAGE_TAGS" | translate }}
    </button>
    <button uiButton class="confirm-button" data-cy="tag-dialog-confirm">
      {{ "FLASHCARD.TAG_DIALOG_CONFIRM" | translate }}
    </button>
  </div>
</form>
