import { Injectable } from '@angular/core';

import { ShareDialogOpenSource } from '@stsm/analytics';
import { SharingBaseService } from '@stsm/global/composite/services/sharing-base.service';
import { SharingDialogService } from '@stsm/global/composite/services/sharing-dialog.service';

@Injectable({ providedIn: 'root' })
export class SharingService implements SharingBaseService {
  constructor(private readonly _sharingDialogService: SharingDialogService) {}

  shareApp(options: { source: ShareDialogOpenSource }): void {
    this._sharingDialogService.openSharingDialog(options.source);
  }

  copyToClipboard(content: string): Promise<void> {
    return navigator.clipboard.writeText(content);
  }

  shareOrDownloadFile(params: { blob: Blob; fileName: string }): Promise<boolean> {
    const url = URL.createObjectURL(params.blob);
    const a = document.createElement('a');

    a.href = url;
    a.download = params.fileName;

    const clickHandler = (): void => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();

    return Promise.resolve(true);
  }
}
