import { Injectable } from '@angular/core';
import { firstValueFrom, map, of, switchMap, take } from 'rxjs';

import { PremiumModalCallSource } from '@stsm/analytics';
import { PremiumWelcomeDialogService } from '@stsm/premium/components/premium-welcome-dialog/premium-welcome-dialog.service';
import { PremiumPlan } from '@stsm/premium/models/premium-plan';
import { PurchaseBaseService } from '@stsm/premium/services/purchase-base.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { AnalyticsService } from '../../shared/services/analytics.service';

import { PaymentDialogComponent } from './payment-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentDialogService extends PurchaseBaseService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _premiumWelcomeDialogService: PremiumWelcomeDialogService,
    private readonly _layoutStore: LayoutStore,
  ) {
    super();
  }

  show(premiumPlan: PremiumPlan, source: PremiumModalCallSource): DialogRef<PaymentDialogComponent, boolean | undefined> {
    this._analyticsService.trackEvent({
      action: 'premium_open_payment_dialog_web',
      properties: { source },
    });

    return this._platformModalService.create({
      component: PaymentDialogComponent,
      data: { premiumPlan, source },
      webOptions: {
        width: '300px',
      },
      useDialogType: true,
    });
  }

  override purchasePremiumPlan({
    premiumPlan,
    source,
  }: {
    premiumPlan: PremiumPlan;
    source: PremiumModalCallSource;
    currentActivePlanID?: string;
  }): Promise<boolean> {
    const showPaymentDialog$ = this.show(premiumPlan, source)
      .afterClosed()
      .pipe(
        switchMap((hasPurchased: boolean | undefined) => {
          if (hasPurchased) {
            return this._userStoreFacade.premiumInfo$.pipe(
              take(1),
              switchMap((premiumInfo: PremiumInfo) => {
                if (premiumInfo.isPremium && !this._layoutStore.isPhoneLayout()) {
                  return this._premiumWelcomeDialogService.openPremiumWelcomeDialog();
                }

                return VOID;
              }),
              map(() => hasPurchased),
            );
          }

          return of(false);
        }),
      );

    return firstValueFrom(showPaymentDialog$);
  }
}
