import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Ad } from '@stsm/global/models/ad';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { IllustrationDirective } from '@stsm/ui-components/disco-color/illustration.directive';

@Component({
  selector: 'app-lead-gen-ad-modal-failure',
  imports: [TranslatePipe, ButtonComponent, NgIf, IllustrationDirective, IconButtonComponent],
  templateUrl: './lead-gen-ad-modal-failure.component.html',
  styleUrls: ['./lead-gen-ad-modal-failure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadGenAdModalFailureComponent {
  @Input() ad: Ad | undefined;

  constructor(private readonly _dialogRef: DialogRef) {}

  protected dismiss(): void {
    this._dialogRef.dismiss();
  }
}
