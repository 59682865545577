import { AsyncPipe, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, OnInit, Signal } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MobileDisclaimerTrackingSource } from '@stsm/analytics';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { Language } from '@stsm/i18n/models/language';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { QueryParamInput } from '@stsm/shared/decorators/extended-inputs.decorator';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { PageBackButtonComponent } from '@stsm/ui-components/page-back-button';

import { AnalyticsService } from '../../shared/services/analytics.service';
import { PlatformService } from '../../shared/services/platform.service';

@Component({
  selector: 'app-mobile-disclaimer',
  templateUrl: './mobile-disclaimer.component.html',
  styleUrls: ['./mobile-disclaimer.component.scss'],
  imports: [AsyncPipe, PageBackButtonComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDisclaimerComponent implements OnInit {
  @Input()
  @QueryParamInput()
  trackingSource?: MobileDisclaimerTrackingSource;

  protected readonly buttonPath$: Observable<string> = this._translationService.language$.pipe(
    map((language: Language) => {
      const isIos = this._platformService.isIOS;
      const store = isIos ? 'appstore' : 'googleplay';
      const imageLanguage = language.value === 'de' ? 'de' : 'en';
      const imageFormat = isIos ? 'svg' : 'png';

      return ['assets/img/welcome-mobile/', store, '_', imageLanguage, '.', imageFormat].join('');
    }),
  );

  protected readonly logoSource: Signal<LogoSource> = computed(() => {
    return this._themingStore.darkThemeActive() ? LogoSource.HAT_WHITE : LogoSource.HAT_BLACK;
  });

  protected readonly imageSource: Signal<string> = computed(() => {
    return this._themingStore.darkThemeActive()
      ? 'assets/shared/pngs/whats_new_redesign_dark.png'
      : 'assets/shared/pngs/whats_new_redesign_light.png';
  });

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _platformService: PlatformService,
    private readonly _translationService: TranslationService,
    private readonly _themingStore: ThemingStore,
    private readonly _location: Location,
  ) {}

  ngOnInit(): void {
    this._analyticsService.trackEvent({
      action: 'mobile_disclaimer_open',
      properties: {
        source: this.trackingSource,
      },
    });
  }

  openDownloadLink(): void {
    this._analyticsService.trackEvent({
      action: 'mobile_disclaimer_open_appstore',
      properties: {
        source: this.trackingSource,
      },
    });
    this._platformService.redirectToAppStore();
  }

  protected goBack(): void {
    this._location.back();
  }
}
