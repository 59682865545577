import { computed, inject, Injectable, Signal } from '@angular/core';
import { patchState, SignalState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { isNil } from 'lodash-es';
import { exhaustMap, filter, Observable, pairwise, pipe, switchMap, take, tap } from 'rxjs';

import { AdsService } from '@stsm/advertisement/services/ads.service';
import { AdsStatusService } from '@stsm/advertisement/services/ads-status.service';
import { AD_2_FLASHCARD_ID, AD_FLASHCARD_ID, AdFlashcardId, Flashcard } from '@stsm/flashcards/types/flashcard';
import { Ad, AdsViewSection } from '@stsm/global/models/ad';
import { filterForValueTransition, switchToVoid } from '@stsm/shared/util/rxjs.util';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { getFlashcardForAd } from '../util/get-flashcard-for-ad';

const DEFAULT_FLASHCARD_AD_OFFSET = 10;
const DEFAULT_FLASHCARD_AD_INTERVAL = 50;

export enum FlashcardAdSequence {
  FC_FC = 'FC_FC',
  FC_AD = 'FC_AD',
  AD_AD = 'AD_AD',
  AD_FC = 'AD_FC',
}

export function nextFlashcardInSequenceIsAd(adSequence: FlashcardAdSequence): boolean {
  return [FlashcardAdSequence.FC_AD, FlashcardAdSequence.AD_AD].includes(adSequence);
}

export function currentFlashcardInSequenceIsAd(adSequence: FlashcardAdSequence): boolean {
  return [FlashcardAdSequence.AD_FC, FlashcardAdSequence.AD_AD].includes(adSequence);
}

interface FlashcardAdsState {
  settingsAdInterval: number;
  settingsAdOffset: number;
  currentAdInterval: number;
  shouldShowTwoAds: boolean | undefined;
  areAdsDisabled: boolean;

  adSequence: FlashcardAdSequence;
  ads: Ad[];
  flashcardAdCounter: number;
  currentAdIndex: number;
}

/**
 * The first two flashcards are always non-ads as isTimeForNextAd is only called when transitioning to the next
 * flashcard. Thus, the first flashcard that can be an ad is the third one.
 */
const INITIAL_FLASHCARD_AD_COUNTER: number = 2;

const initialState: FlashcardAdsState = {
  settingsAdInterval: DEFAULT_FLASHCARD_AD_INTERVAL,
  settingsAdOffset: DEFAULT_FLASHCARD_AD_OFFSET,
  currentAdInterval: DEFAULT_FLASHCARD_AD_OFFSET,
  shouldShowTwoAds: undefined,
  areAdsDisabled: false,

  adSequence: FlashcardAdSequence.FC_FC,
  ads: [],
  flashcardAdCounter: INITIAL_FLASHCARD_AD_COUNTER,
  currentAdIndex: 0,
};

interface AdFlashcardGroup {
  [AD_FLASHCARD_ID]: Flashcard | undefined;
  [AD_2_FLASHCARD_ID]: Flashcard | undefined;
}

@Injectable()
export class FlashcardsAdService {
  private readonly _state: SignalState<FlashcardAdsState> = signalState<FlashcardAdsState>(initialState);

  private readonly _isAdAvailable: Signal<boolean> = computed(() => {
    const ads = this._state.ads();
    const currentAdIndex = this._state.currentAdIndex();

    return !isNil(ads[currentAdIndex]);
  });

  private readonly _areTwoAdsAvailable: Signal<boolean> = computed(() => {
    const ads = this._state.ads();
    const currentAdIndex = this._state.currentAdIndex();

    return !isNil(ads[currentAdIndex]) && !isNil(ads[currentAdIndex + 1]);
  });

  private readonly _canShowTwoAds: Signal<boolean> = computed(
    () => (this._state.shouldShowTwoAds() ?? false) && this._areTwoAdsAvailable(),
  );

  private readonly _adsService: AdsService = inject(AdsService);
  private readonly _userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  private readonly _adsStatusService: AdsStatusService = inject(AdsStatusService);

  constructor() {
    /**
     * Initialize ad settings with values from user settings
     */
    rxMethod<User>(
      pipe(
        take(1),
        tap((user: User): void => {
          patchState(this._state, {
            currentAdInterval: user.settings.flashcardAdOffset ?? DEFAULT_FLASHCARD_AD_OFFSET,
            settingsAdOffset: user.settings.flashcardAdOffset ?? DEFAULT_FLASHCARD_AD_OFFSET,
            settingsAdInterval: user.settings.flashcardAdInterval ?? DEFAULT_FLASHCARD_AD_INTERVAL,
          });
        }),
      ),
    )(this._userStoreFacade.user$);

    /**
     * Sync state with AdsStatusService
     */
    rxMethod<boolean>(pipe(tap((areAdsDisabled: boolean) => patchState(this._state, { areAdsDisabled }))))(
      this._adsStatusService.adsDisabled$,
    );

    /**
     * Whenever all ads have been shown (and thus the adSequence returns to FC_FC),
     * we move the current ad index in order to use the next group.
     */
    rxMethod<FlashcardAdSequence>(
      pipe(
        pairwise(),
        filter(
          ([prev, next]: [FlashcardAdSequence, FlashcardAdSequence]) =>
            prev !== FlashcardAdSequence.FC_FC && next === FlashcardAdSequence.FC_FC,
        ),
        tap(() => {
          // move current ad index
          patchState(this._state, (state: FlashcardAdsState) => ({
            currentAdIndex: state.currentAdIndex + (this._canShowTwoAds() ? 2 : 1),
          }));
        }),
      ),
    )(this._state.adSequence);

    /**
     * We always fetch more ads if the last ad has been used.
     *
     * It is important to check for the value transition from "ad is available" to "ad is not available" to avoid
     * endless loops, e.g. if the backend does not return any ads
     */
    rxMethod(
      pipe(
        filterForValueTransition({ from: true, to: false }),
        exhaustMap(() => this._fetchAds()),
      ),
    )(this._isAdAvailable);

    // initial ad fetch
    rxMethod(pipe(switchMap(() => this._fetchAds())))(this._userStoreFacade.userAvailable$);
  }

  /**
   * needs to be called initially to define whether two ads should be shown in a row
   *
   * @param config config
   * @param config.shouldShowTwoAds whether to show two ads in a row
   */
  init(config: { shouldShowTwoAds: boolean }): void {
    patchState(this._state, { shouldShowTwoAds: config.shouldShowTwoAds });
  }

  isTimeForNextAd(): FlashcardAdSequence {
    if (isNil(this._state().shouldShowTwoAds)) {
      throw new Error('Programming error: Please call flashcardsAdService.init() first!');
    }

    const { areAdsDisabled, flashcardAdCounter, currentAdInterval, settingsAdInterval, adSequence } = this._state();

    if (areAdsDisabled) {
      patchState(this._state, { adSequence: FlashcardAdSequence.FC_FC });

      return this._state().adSequence;
    }

    if (this._isAdAvailable() && flashcardAdCounter >= currentAdInterval) {
      /**
       * Make the first ad appear when reaching the currentAdInterval, but don't reset the flashcardAdCounter. The next
       * time isTimeForNextAd() is called, the counter will be reset and the second ad in a row will appear.
       */
      if (this._canShowTwoAds() && adSequence === FlashcardAdSequence.FC_FC) {
        this._incrementFlashcardAdCounter();
        patchState(this._state, { adSequence: FlashcardAdSequence.FC_AD });

        return this._state().adSequence;
      }

      patchState(this._state, {
        adSequence: this._canShowTwoAds() ? FlashcardAdSequence.AD_AD : FlashcardAdSequence.FC_AD,
        flashcardAdCounter: 0,
        // after the first advertisement, use the adInterval from the user settings
        currentAdInterval: settingsAdInterval,
      });

      return this._state().adSequence;
    }

    patchState(this._state, {
      adSequence: [FlashcardAdSequence.FC_AD, FlashcardAdSequence.AD_AD].includes(adSequence)
        ? FlashcardAdSequence.AD_FC
        : FlashcardAdSequence.FC_FC,
    });
    this._incrementFlashcardAdCounter();

    return this._state().adSequence;
  }

  /**
   * depending on the position in the current adSequence, either {@link AD_FLASHCARD_ID} or ${@link AD_2_FLASHCARD_ID} is returned
   *
   * @param adSequence the adSequence
   * @param position the position within the adSequence
   */
  getAdFlashcardIdForSequence(adSequence: FlashcardAdSequence, position: 'current' | 'next'): AdFlashcardId | undefined {
    switch (adSequence) {
      case FlashcardAdSequence.FC_AD:
        return position === 'current' ? undefined : AD_FLASHCARD_ID;
      case FlashcardAdSequence.AD_AD:
        return position === 'current' ? AD_FLASHCARD_ID : AD_2_FLASHCARD_ID;
      case FlashcardAdSequence.AD_FC:
        return position === 'current' ? (this._canShowTwoAds() ? AD_2_FLASHCARD_ID : AD_FLASHCARD_ID) : undefined;
      case FlashcardAdSequence.FC_FC:
      default:
        return undefined;
    }
  }

  reset(): void {
    patchState(this._state, (state: FlashcardAdsState) => ({
      flashcardAdCounter: INITIAL_FLASHCARD_AD_COUNTER,
      currentAdInterval: state.settingsAdOffset,
    }));
  }

  getAdFlashcard(flashcardId: AdFlashcardId | undefined): Flashcard | undefined {
    if (isNil(flashcardId)) {
      return undefined;
    }

    const group = this._getCurrentAdFlashcardGroup();

    return group[flashcardId];
  }

  private _incrementFlashcardAdCounter(): void {
    patchState(this._state, (state: FlashcardAdsState) => ({ flashcardAdCounter: state.flashcardAdCounter + 1 }));
  }

  private _fetchAds(): Observable<void> {
    return this._adsService.getAds({ view: AdsViewSection.FLASHCARDS }).pipe(
      tap((ads: Ad[]): void => {
        if (ads.length === 0) {
          return;
        }

        patchState(this._state, (state: FlashcardAdsState) => ({ ads: [...state.ads, ...ads] }));
      }),
      switchToVoid(),
    );
  }

  private _getCurrentAdFlashcardGroup(): AdFlashcardGroup {
    const { ads, currentAdIndex } = this._state();

    const firstAd = ads[currentAdIndex];
    const secondAd = ads[currentAdIndex + 1];

    return {
      [AD_FLASHCARD_ID]: !isNil(firstAd) ? getFlashcardForAd(firstAd, AD_FLASHCARD_ID) : undefined,
      [AD_2_FLASHCARD_ID]: !isNil(secondAd) ? getFlashcardForAd(secondAd, AD_2_FLASHCARD_ID) : undefined,
    };
  }
}
