import { CdkDrag } from '@angular/cdk/drag-drop';
import { AsyncPipe, KeyValuePipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  Signal,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { isNil } from 'lodash-es';
import { Observable } from 'rxjs';

import { ChatGptPromptService } from '@stsm/global/composite/services/chat-gpt-prompt.service';
import { THEME_OPTIONS, ThemeOption, ThemeSetting } from '@stsm/global/composite/services/theming.store';
import type { GptModel } from '@stsm/global/models/ai/chat-gpt.interface';
import { supportedChatGptModels } from '@stsm/global/models/ai/chat-gpt.interface';
import { TranslationToolbarComponent } from '@stsm/i18n/components/translation-toolbar.component';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { DevtoolsEnvironment } from '@stsm/shared/models/devtools-environment';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { ScreenDimensionsService } from '@stsm/shared/services/screen-dimensions.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';

import { DevtoolsAction, DevtoolsActionLabel, DevtoolsInformation, DevtoolsService } from '../../services/devtools.service';
import {
  FeatureToggle,
  FeatureToggleMap,
  FeatureToggleService,
  platformFeatureTogglesConfigMap,
} from '../../services/feature-toggle.service';
import { EXPERIMENT_FLAG_TO_OPTIONS_MAPPING } from '../../utils/testgroup-options-mapping';

interface DragPosition {
  readonly x: number;
  readonly y: number;
}

const SIMULATE_SAFE_AREAS_KEY: string = 'simulateSafeAreas';

const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

type Weekdays = typeof WEEKDAYS;

type Weekday = Weekdays[number];

@UntilDestroy()
@Component({
  selector: 'app-devtools',
  templateUrl: './devtools.component.html',
  styleUrls: ['./devtools.component.scss'],
  imports: [
    ButtonComponent,
    CdkDrag,
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    TranslationToolbarComponent,
    NgIf,
    AsyncPipe,
    KeyValuePipe,
    LetDirective,
    IconButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevtoolsComponent implements OnInit {
  @ViewChild(CdkDrag, { static: false }) timerDrag?: CdkDrag;

  get themeOptions(): ThemeOption[] {
    return THEME_OPTIONS;
  }

  readonly experimentToOptionsMapping: Record<string, { label: string; value: string }[]> = EXPERIMENT_FLAG_TO_OPTIONS_MAPPING;
  readonly supportedChatGptModels: typeof supportedChatGptModels = supportedChatGptModels;

  get environment(): DevtoolsEnvironment {
    return this._devtoolsService.resolveDevToolsEnvironmentFromLocalStorage();
  }

  get hideDevtoolsButton(): boolean {
    return this._devtoolsService.hideDevtoolButton;
  }

  featureToggles: Signal<FeatureToggleMap> = this._featureToggleService.featureToggles;
  additionalDevtoolsInformation: Signal<DevtoolsInformation[]> = this._devtoolsService.additionalInformation;
  aiAssistantGptVersion: Signal<GptModel | undefined> = this._devtoolsService.aiAssistantGptVersion;
  additionalDevtoolsActions: Signal<DevtoolsAction[]> = this._devtoolsService.additionalActions;
  experiments$: Observable<Record<string, string>> = this._devtoolsService.getExperimentValues();

  safeAreasFormControl: FormControl<boolean> = new FormControl(
    this._browserStorageService.getItemLocalStorage(SIMULATE_SAFE_AREAS_KEY) ?? false,
    { nonNullable: true },
  );

  protected readonly currentDragPosition: WritableSignal<DragPosition> = signal({ x: 0, y: 0 });
  protected readonly isMinimized: WritableSignal<boolean> = signal(this._devtoolsService.checkIfMinimized());
  protected readonly weekdays: Weekdays = WEEKDAYS;

  protected readonly streakCustomizationEnabled: WritableSignal<boolean> = signal(
    this._devtoolsService.resolveStreakCustomizationEnabled(),
  );

  protected readonly currentStreak: WritableSignal<number | null> = signal(this._devtoolsService.resolveCurrentStreak());
  protected readonly weeklyStreak: WritableSignal<Record<Weekday, string>> = signal(this._devtoolsService.resolveWeeklyStreak());

  private get _snapRightX(): number {
    // subtract 44px (width of snappy button) and add 6px as a small displacement
    return this._screenWidth - 44 + 6;
  }

  private get _screenWidth(): number {
    return this._screenDimensionsService.screenWidth;
  }

  constructor(
    private readonly _devtoolsService: DevtoolsService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _renderer: Renderer2,
    private readonly _screenDimensionsService: ScreenDimensionsService,
    private readonly _ngZone: NgZone,
    private readonly _chatGptPromptService: ChatGptPromptService,
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
    private readonly _featureToggleService: FeatureToggleService,
    @Inject(Window) private readonly _window: Window,
  ) {
    effect(() => this._devtoolsService.setIsMinimized(this.isMinimized()));

    if (this._devtoolsService.areDevtoolsAllowed) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).toggleDevTools = (): void => {
        this.toggleIsMinimized();
      };

      effect(() => this._devtoolsService.setStreakCustomizationEnabled(this.streakCustomizationEnabled()));
      effect(() => this._devtoolsService.setCurrentStreak(this.currentStreak() ?? 0));
      effect(() => this._devtoolsService.setWeeklyStreak(this.weeklyStreak()));
    }
  }

  ngOnInit(): void {
    this._setSafeArea(this._browserStorageService.getItemLocalStorage(SIMULATE_SAFE_AREAS_KEY) ?? false);
    this.safeAreasFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((shouldActivate: boolean) => {
      this._setSafeArea(shouldActivate);
    });

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.CLEAR_STORAGE,
      action: () => {
        this._browserStorageService.clearLocalStorage();
        this._browserStorageService.clearSessionStorage();
        this._browserStorageService.clearCookie();
        this._window.location.reload();
      },
    });

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.SET_PROMPT_DEFAULTS,
      action: () => this._chatGptPromptService.setPromptDefaults(),
    });

    this._screenDimensionsService.resize$.pipe(untilDestroyed(this)).subscribe(() => this.snapToSide());

    this._devtoolsService.registerCloseDevtools(() => this.isMinimized.set(true));
  }

  toggleIsMinimized(): void {
    this.isMinimized.set(!this.isMinimized());
  }

  changeTestGroup(testGroupName: string, testGroupValue: number | string): void {
    this._devtoolsService.changeTestGroup(testGroupName, testGroupValue);
  }

  changeEnvironment(environment: DevtoolsEnvironment): void {
    this._devtoolsService.changeEnvironment(environment);
  }

  changeAiAssistantGptVersion(version: GptModel): void {
    this._devtoolsService.changeAiAssistantGptVersion(version);
  }

  changeTheme(theme: ThemeSetting): void {
    void this._devtoolsService.changeTheme(theme);
  }

  updateStreakDayStatus(weekday: Weekday, status: string): void {
    this.weeklyStreak.update((currentWeeklyStreak: Record<Weekday, string>) => {
      return { ...currentWeeklyStreak, [weekday]: status };
    });
  }

  setFeatureToggle(featureToggle: FeatureToggle, isEnabled: boolean): void {
    this._devtoolsService.setFeatureToggle(featureToggle, isEnabled);
  }

  showFeatureToggle(featureToggle: FeatureToggle): boolean {
    const config = platformFeatureTogglesConfigMap[featureToggle];

    return isNil(config) || (config.platform === 'mobile' && this._isMobileApp) || (config.platform === 'web' && !this._isMobileApp);
  }

  protected snapToSide(): void {
    // strangely ngZone is required here although setTimeout should be monkey-patched by zone
    this._ngZone.run(() => {
      setTimeout(() => {
        if (!isNil(this.timerDrag)) {
          const newDragPosition: DragPosition = this.timerDrag.getFreeDragPosition();
          const maxY: number = this._screenDimensionsService.screenHeight - 50;

          if (newDragPosition.x < this._screenWidth / 2) {
            this.currentDragPosition.set({ x: 0, y: Math.min(newDragPosition.y, maxY) }); // snap left
          } else {
            this.currentDragPosition.set({ x: this._snapRightX, y: Math.min(newDragPosition.y, maxY) }); //snap right
          }
        }
      });
    });
  }

  private _setSafeArea(shouldActivate: boolean): void {
    const classToToggle = 'simulate-safe-areas';
    this._browserStorageService.setItemLocalStorage(SIMULATE_SAFE_AREAS_KEY, shouldActivate);

    if (shouldActivate) {
      this._renderer.addClass(document.body, classToToggle);
    } else {
      this._renderer.removeClass(document.body, classToToggle);
    }
  }
}
